<template>
  <b-modal
    id="xlsModal"
    title="XLSX"
    ok-only
    centered
    size="lg"
    ok-title="Скачать"
  >
    <div slot="modal-footer" class="w-100">
      <div class="d-flex align-items-center justify-content-center w-100">
        <b-button
          size="sm"
          class="p-50 w-50 text-center"
          :variant="firstLoad ? 'secondary' : 'primary'"
          :disabled="firstLoad"
          @click="downLoadXls"
        >
          <b-spinner
            v-if="firstLoad"
            class="mr-1"
            style="width: 1.5rem; height: 1.5rem"
          />

          <template v-else>
            <FeatherIcon icon="DownloadIcon" />
            Скачать отчет по взыскателям
          </template>
        </b-button>

        <b-button
          :disabled="secondLoad"
          size="sm"
          class="ml-1 p-50 w-50"
          :variant="secondLoad ? 'secondary' : 'primary'"
          @click="downLoadXlsOperator"
        >
          <b-spinner
            v-if="secondLoad"
            class="mr-1"
            style="width: 1.5rem; height: 1.5rem"
          />
          <template v-else>
            <FeatherIcon icon="DownloadIcon" />
            Скачать отчет по коллекторам
          </template>
        </b-button>
      </div>
      <div
        class="d-flex align-items-center justify-content-between w-100 mt-50"
      >
        <b-button
          size="sm"
          class="p-50 w-50 text-center"
          :variant="thirdLoad ? 'secondary' : 'primary'"
          :disabled="thirdLoad"
          @click="downLoadXlsPaymentHistory"
        >
          <b-spinner
            v-if="thirdLoad"
            class="mr-1"
            style="width: 1.5rem; height: 1.5rem"
          />

          <template v-else>
            <FeatherIcon icon="DownloadIcon" />
            Скачать отчет по платежам
          </template>
        </b-button>
      </div>
    </div>
    <b-form-group label="Дата с">
      <flat-pickr
        v-model="item.created_date_start"
        class="form-control bg-white"
        :config="configdateTimePicker"
      />
    </b-form-group>

    <b-form-group label="Дата по">
      <flat-pickr
        v-model="item.created_date_end"
        class="form-control bg-white"
        :config="configdateTimePicker"
      />
    </b-form-group>
  </b-modal>
</template>

<script lang="ts">
import { BModal, BFormGroup, BButton, BSpinner } from "bootstrap-vue";
import { mapActions } from "vuex";
import moment from "moment/moment";
import flatPickr from "vue-flatpickr-component";
// eslint-disable-next-line import/extensions
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    flatPickr,
    BSpinner,
  },
  props: {
    xlsValue: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      firstLoad: false,
      secondLoad: false,
      thirdLoad: false,
      configdateTimePicker: {
        dateFormat: "Y-m-d",
        locale: Russian,
      },
      exportXls: false,
      item: {
        created_date_start: null,
        created_date_end: null,
      },
    };
  },
  computed: {
    moment() {
      return moment;
    },
  },
  methods: {
    ...mapActions("portfolios", [
      "DOWNLOAD_CALL_HIS_XLS",
      "DOWNLOAD_CALL_HIS_XLS_OPERATOR",
      "DOWNLOAD_XLS_PAYMENT_HISTORY",
    ]),

    async downLoadXls() {
      try {
        this.firstLoad = true;
        const res = await this.DOWNLOAD_CALL_HIS_XLS({
          company: this.xlsValue,
          ...this.item,
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Отчет по взыскателям-${moment(new Date(), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        await this.$_okToast();
      } catch (error) {
        await this.$_errorToast(error);
        this.firstLoad = false;
      }
      this.firstLoad = false;
    },

    async downLoadXlsOperator() {
      try {
        this.secondLoad = true;
        const res = await this.DOWNLOAD_CALL_HIS_XLS_OPERATOR({
          company: this.xlsValue,
          ...this.item,
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Отчет по коллекторам-${moment(new Date(), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        await this.$_okToast();
      } catch (error) {
        await this.$_errorToast(error);
        this.secondLoad = false;
      }
      this.secondLoad = false;
    },

    async downLoadXlsPaymentHistory() {
      try {
        this.thirdLoad = true;
        console.log({
          company: this.xlsValue,
          ...this.item,
        });
        
        const res = await this.DOWNLOAD_XLS_PAYMENT_HISTORY({
          company: this.xlsValue,
          ...this.item,
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Скачать отчет по платежам-${moment(new Date(), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        await this.$_okToast();
      } catch (error) {
        await this.$_errorToast(error);
        this.thirdLoad = false;
      }
      this.thirdLoad = false;
    },
  },
};
</script>
<style>
#xlsModal .modal-lg {
  max-width: 531px;
  margin: 0 auto;
}
</style>
